html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	-webkit-tap-highlight-color: transparent;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	font-family: Pretendard, sans-serif;
	overflow-x: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	background: inherit;
	box-shadow: none;
    border: none;
	border-radius: 0;
	padding: 0;
	overflow: visible;
	cursor: pointer;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-webkit-tap-highlight-color: transparent;
  	-webkit-touch-callout: none;
	font-family: Pretendard;
}
div {
	box-sizing: border-box;
}
p {
	word-break: keep-all;
	white-space: pre-line;
}
img {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-tap-highlight-color: transparent;
}
input {
	box-sizing: border-box;
	border: none;
	touch-action: manipulation;
}
input:focus {
	outline: none;
}
input::placeholder {
	color: #AAADB9;
}
input[type="checkbox"] {
	-webkit-tap-highlight-color: transparent;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
textarea {
	border: none;
	resize: none;
	outline: none;
	box-sizing: border-box;
	line-height: 150%;
	font-family: Pretendard;
	touch-action: manipulation;
}
textarea::placeholder {
	color: #AAADB9;
}